<template>
  <div class="new-tradie">
    <MainAppBar>
      <template v-slot:title>
        <span v-if="tradie.primary_contact_name">
          {{ tradie.primary_contact_name }}
        </span>

        <span v-else> New Tradie </span>
      </template>

      <template v-slot:actions>
        <arrow-button
          :to="{ name: 'new-tradie-certification' }"
          :done="false"
          :disabled="true"
          arrow-right
          replace
        >
          Business Certification
        </arrow-button>

        <arrow-button
          :to="{ name: 'new-tradie-details' }"
          :done="false"
          :disabled="true"
          arrow-left
          arrow-right
          replace
        >
          Tradie Details
        </arrow-button>

        <arrow-button
          :to="{ name: 'new-business-details' }"
          :done="false"
          :disabled="true"
          arrow-left
          arrow-right
          replace
        >
          Business Details
        </arrow-button>

        <arrow-button
          :to="{ name: 'new-tradie-overview' }"
          :done="false"
          :disabled="true"
          arrow-left
          replace
        >
          Overview
        </arrow-button>
      </template>
    </MainAppBar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapMutations, mapState } from 'vuex'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'NewTradie',

  components: { ArrowButton, MainAppBar },

  data() {
    return {
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState('newTradie', ['tradie']),
  },

  methods: {
    ...mapMutations({
      resetTradieDetails: 'newTradie/resetTradieDetails',
    }),
  },

  beforeRouteLeave(to, from, next) {
    this.resetTradieDetails()
    next()
  },
}
</script>

<style lang="scss">
.new-tradie {
  .v-skeleton-loader {
    &__bone {
      padding: 0 !important;
    }
  }
}
</style>
